import React, { FC, useContext } from 'react'
import { Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { AuthContext } from 'context/AuthContext'
import { ReportError } from 'types/report'
import * as Styled from './ErrorPage.styled'

const ErrorPage: FC<Partial<ReportError>> = ({ message, code }) => {
    const { error } = useContext(AuthContext)

    return (
        <Styled.ErrorWrapper>
            <Styled.SVGIcon fontSize={'large'}>
                <ErrorIcon color={'error'} />
            </Styled.SVGIcon>
            <div>
                <Typography variant="h5" gutterBottom>
                    Error {code || error?.code}
                </Typography>
                <Styled.ErrorMessage variant="body1" gutterBottom>
                    {message || error?.message || 'Something went wrong'}. Please contact our support team.
                </Styled.ErrorMessage>
            </div>
        </Styled.ErrorWrapper>
    )
}

export default ErrorPage
