import React, { FC, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'
import Dashboard from 'pages/Dashboard'
import ErrorPage from 'pages/ErrorPage'

const AppRoutes: FC = () => {
    const { token, error } = useContext(AuthContext)

    return (
        <Routes>
            {token && ['/', '/dmic', '/tsm'].map((path) => <Route key={path} path={path} element={<Dashboard />} />)}
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage {...(!error && { message: 'Wrong Direction' })} />} />
        </Routes>
    )
}

export default AppRoutes
