import axios, { AxiosRequestConfig } from 'axios'
import envJSON from 'config/env.json'

export class APIHelper {
    private static instance: APIHelper
    private apiUrl: string
    private token: string

    private constructor() {
        const isProdEnv = process.env.NODE_ENV === 'production'
        const appName = location.pathname.split('/')[1].toUpperCase() || 'DMIC'
        this.token =
            (isProdEnv
                ? new URLSearchParams(location.search).get('token')
                : process.env[`REACT_APP_${appName}_TOKEN`]) || ''
        this.apiUrl = isProdEnv
            ? `${location.protocol}//api.${location.host}${location.pathname}`
            : envJSON.staging.apiUrl + location.pathname
    }

    /**
     * Normal GET request using axios. Providing the dynamic environment API URL as a prefix.
     * @param serviceUrl - Relative service URL including query params.
     * @param config - GET request config including `headers`.
     * @returns normal axios response.
     */
    public get(serviceUrl: string, config?: AxiosRequestConfig) {
        const url = this.apiUrl + serviceUrl
        return axios.get(url, config)
    }

    /**
     * Normal POST request using axios. Providing the dynamic environment API URL as a prefix.
     * @param serviceUrl - Relative service URL including query params.
     * @param data - Payload data to be sent to BE over the POST request.
     * @param config - POST request config including `headers`.
     * @returns normal axios response.
     */
    public post(serviceUrl: string, data?: unknown, config?: AxiosRequestConfig) {
        const url = this.apiUrl + serviceUrl
        return axios.post(url, data, config)
    }

    /**
     * @returns `headers` object to be sent in a request config
     * including JWT token fetched from the window URL.
     */
    public getHeaders(): AxiosRequestConfig {
        return {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
        }
    }

    /**
     * @returns the dynamic API URL based on the current environment.
     */
    public getAPIUrl(): string {
        return this.apiUrl
    }

    public static getInstance(): APIHelper {
        if (!APIHelper.instance) {
            APIHelper.instance = new APIHelper()
        }
        return APIHelper.instance
    }
}
